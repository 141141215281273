export default defineStore('AnchorStore', {
    state: () => ({
        anchors: [],
        isScrolling: false,
    }),

    actions: {
        setAnchor(anchor) {
            if (!Array.isArray(this.anchors)) {
                this.anchors = [];
            }

            const anchorExists = this.anchors.find((a) => a.id === anchor);

            if (!anchorExists) {
                this.anchors.push({
                    id: anchor,
                    isActive: false,
                });
            }
        },

        resetAnchors() {
            this.anchors = [];
        },

        setActiveAnchor(anchorId) {
            if (this.isScrolling) return;
            this.anchors = this.anchors.map((anchor) => ({
                ...anchor,
                isActive: anchor.id === anchorId,
            }));
        },

        scrollToAnchor(anchorId) {
            if (this.isScrolling) return;

            this.setActiveAnchor(anchorId);
            this.isScrolling = true;

            const { lenis } = useLenis();
            const { easeInOutQuart } = useEasing();

            lenis.value?.scrollTo(`#${anchorId}`, {
                duration: 1,
                easing: easeInOutQuart,
                lock: true,
                onComplete: () => {
                    this.isScrolling = false;
                },
            });
        },
    },

    getters: {
        getAnchors(state) {
            return state.anchors;
        },
    },
});
